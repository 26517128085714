import { useSearchParams } from "react-router-dom";

export const useFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const filter = (key: string) => searchParams.get(key) ?? "";

  const setsearchParams = (key: string, value: string) => {
    if (value) {
      if (searchParams.has(key)) {
        searchParams.delete(key);
      }
      searchParams.append(key, value);
      setSearchParams(searchParams);
    } else {
      searchParams.delete(key);
      setSearchParams(searchParams);
    }
  };

  return { searchParams, filter, setsearchParams };
};
