import React, { createContext, useCallback, useEffect, useState } from "react";

// third-party
import { dispatch, useSelector } from "../store/index";
import {
  setlogout,
  setLogin,
  setAuthRole,
  setLogoutStatus,
  setLoggingInStatus,
} from "../store/reducers/login";

// project import
import Loader from "../components/Loader";
import { KeyedObject } from "../types/root";
import { Auth0ContextType } from "../types/auth";
import { useAuth0 } from "@auth0/auth0-react";
import { useLazyGetOrgRolesListQuery } from "store/reducers/roles";
import { enqueueSnackbar } from "notistack";
import captureSentryError from "helper/captureSentryError";

// ==============================|| AUTH0 CONTEXT & PROVIDER ||============================== //

const Auth0Context = createContext<Auth0ContextType | null>(null);

export const Auth0Provider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const state = useSelector((state) => state.auth);
  const [params, setParams] = useState(localStorage.getItem('params'));
  
  const [fetch, { isLoading: loading, isError: error }] =
    useLazyGetOrgRolesListQuery();

  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout: authLogout,
    getAccessTokenSilently,
    isLoading: authloading,
    error: authError
  } = useAuth0();

  const isLoading = authloading || loading;

  const setRole = (option: {
    EmployerID: string
    RoleName: string;
    RoleID: string;
    FirstName: string;
    LastName: string;
    HealthCheckToolDisable?: boolean
  }) => {
    dispatch(
      setAuthRole({
        isLoggedIn: true,
        isInitialized: true,
        FirstName: option.FirstName,
        LastName: option.LastName,
        user: { ...user, ...option },
      })
    );
    localStorage.setItem(
      "EmployeeDetails",
      JSON.stringify({ ...user, ...option })
    );
  };

  const logout = useCallback(() => {
    dispatch(setLogoutStatus({ isLoggingOut: true }));
    dispatch(setlogout());
    if (params === "true") 
      authLogout({ logoutParams: { returnTo: window.location.origin } });
    else
      authLogout({ logoutParams: { returnTo: `${process.env.REACT_APP_AVC_DOMAIN}/users/sign_out?logout=true` } });

    localStorage.removeItem("EmployeeDetails");
    localStorage.removeItem("params");
    localStorage.removeItem("isConsentDialogShown");
    setParams(null);
    dispatch(setLogoutStatus({ isLoggingOut: true }));
  }, [authLogout]);

  useEffect(() => {
    const init = async () => {
      if (authError) {
        captureSentryError(authError, params ?? "")
        enqueueSnackbar(authError?.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          autoHideDuration: 2000,
        }
        );

        if (params === "true") {
          authLogout({
            logoutParams: {
              returnTo: window.location.origin
            }
          });
        } else {
          authLogout({
            logoutParams: {
              returnTo: `${process.env.REACT_APP_AVC_DOMAIN}/users/sign_out?logout=true`
            }
          });
        }

        dispatch(setLogoutStatus({ isLoggingOut: false }));
        dispatch(setlogout());
        if (params === "true")
          authLogout({ logoutParams: { returnTo: window.location.origin } });
        else
          authLogout({ logoutParams: { returnTo: `${process.env.REACT_APP_AVC_DOMAIN}/users/sign_out?logout=true` } });
        localStorage.removeItem("EmployeeDetails");
        localStorage.removeItem("params");
        setParams(null);
        localStorage.removeItem("isConsentDialogShown");
      } else {
        if (error) return;
        try {
          const isLoggedIn = isAuthenticated;
          dispatch(setLoggingInStatus({ isLoggingIn: true }));
          if (!isLoading) {
            if (isLoggedIn) {
              const getUser = localStorage.getItem("EmployeeDetails") ?? "";
              const token = await getAccessTokenSilently();
              if (token) {
                const newState = {
                  isLoggedIn: true,
                  token,
                  user: {
                    id: user?.sub,
                    email: user?.email,
                  },
                };
                dispatch(setLogin(newState));
                if (!getUser) {
                  const { data } = await fetch("");
                  if (data?.data.singleLogin) {
                    const Employer = data?.data.organisation[0];
                    const EmployerID = Employer.EmployerID;
                    const EmployerName = Employer.EmployerName;
                    const RoleID = Employer.Roles[0].MMRoleID;
                    const RoleName = Employer.Roles[0].RoleName;
                    const singleLogin = data?.data.singleLogin;
                    const FirstName = data?.data?.FirstName;
                    const LastName = data?.data?.LastName;
                    const HealthCheckToolDisable = Boolean(Employer?.HealthCheckToolDisable)
                    dispatch(
                      setAuthRole({
                        isLoggedIn: true,
                        isInitialized: true,
                        FirstName: FirstName,
                        LastName: LastName,
                        user: {
                          ...user,
                          EmployerID,
                          RoleID,
                          RoleName,
                          EmployerName,
                          singleLogin,
                          HealthCheckToolDisable
                        },
                      })
                    );
                    localStorage.setItem(
                      "EmployeeDetails",
                      JSON.stringify({
                        ...user,
                        EmployerID,
                        RoleID,
                        RoleName,
                        EmployerName,
                        singleLogin,
                        FirstName,
                        LastName,
                        HealthCheckToolDisable
                      })
                    );
                    dispatch(setLoggingInStatus({ isLoggingIn: false }));
                  } else {
                    dispatch(setLoggingInStatus({ isLoggingIn: false }));
                  }
                } else {
                  const savedUser = JSON.parse(getUser);
                  dispatch(
                    setAuthRole({
                      isLoggedIn: true,
                      isInitialized: true,
                      FirstName: savedUser.FirstName,
                      LastName: savedUser.LastName,
                      user: {
                        ...user,
                        EmployerID: savedUser.EmployerID,
                        RoleID: savedUser.RoleID,
                        RoleName: savedUser.RoleName,
                        EmployerName: savedUser.EmployerName,
                        singleLogin: savedUser.singleLogin,
                        HealthCheckToolDisable: savedUser.HealthCheckToolDisable
                      },
                    })
                  );
                  dispatch(setLoggingInStatus({ isLoggingIn: false }));
                }
              } else {
                dispatch(setLoggingInStatus({ isLoggingIn: false }));
              }
            } else {
              dispatch(setlogout());
              localStorage.removeItem("EmployeeDetails");
              localStorage.removeItem("params");
              setParams(null);
              localStorage.removeItem("isConsentDialogShown");
              dispatch(setLoggingInStatus({ isLoggingIn: false }));
            }
          } else {
            dispatch(setLoggingInStatus({ isLoggingIn: false }));
          }
        } catch (err) {
          captureSentryError(err, params ?? "")
          if (params === "true")
            authLogout({ logoutParams: { returnTo: window.location.origin } });
          else
            authLogout({ logoutParams: { returnTo: `${process.env.REACT_APP_AVC_DOMAIN}/users/sign_out?logout=true` } });

          dispatch(setlogout());
          localStorage.removeItem("EmployeeDetails");
          localStorage.removeItem("params");
          setParams(null);
          localStorage.removeItem("isConsentDialogShown");
          dispatch(setLoggingInStatus({ isLoggingIn: false }));
          console.log("auth error", err);
        }
      }
    };

    init();
  }, [fetch, getAccessTokenSilently, isAuthenticated, isLoading, user, logout, error, authLogout, authError]);

  const login = async (options?: KeyedObject) => {
    try {
      dispatch(setLoggingInStatus({ isLoggingIn: true }));
      await loginWithRedirect(options);
      const isLoggedIn = isAuthenticated;

      if (isLoggedIn) {
        const token = await getAccessTokenSilently();
        const newState = {
          isLoggedIn: true,
          token,
          user: {
            id: user?.sub,
            avatar: user?.picture,
            email: user?.email,
            name: user?.name,
            tier: "Premium",
          },
        };
        dispatch(setLogin(newState));
        const { data } = await fetch("");
        if (data?.data.singleLogin) {
          const Employer = data?.data.organisation[0];
          const EmployerID = Employer.EmployerID;
          const EmployerName = Employer.EmployerName;
          const RoleID = Employer.Roles[0].MMRoleID;
          const RoleName = Employer.Roles[0].RoleName;
          const singleLogin = data?.data.singleLogin;
          const FirstName = data?.data?.FirstName;
          const LastName = data?.data?.LastName;
          const HealthCheckToolDisable = Boolean(Employer?.HealthCheckToolDisable)
          dispatch(
            setAuthRole({
              isLoggedIn: true,
              isInitialized: true,
              FirstName: FirstName,
              LastName: LastName,
              user: {
                ...user,
                EmployerID,
                RoleID,
                RoleName,
                EmployerName,
                singleLogin,
                HealthCheckToolDisable
              },
            })
          );
          localStorage.setItem(
            "EmployeeDetails",
            JSON.stringify({
              ...user,
              EmployerID,
              RoleID,
              RoleName,
              EmployerName,
              singleLogin,
              FirstName,
              LastName,
              HealthCheckToolDisable
            })
          );
          dispatch(setLoggingInStatus({ isLoggingIn: false }));
        } else {
          dispatch(setLoggingInStatus({ isLoggingIn: false }));
        }
        // dispatch(setLoggingInStatus({ isLoggingIn: false }));
      } else {
        dispatch(setLoggingInStatus({ isLoggingIn: false }));
      }
    } catch (error) {
      captureSentryError(error, params ?? "")
      console.log("error", error);
      dispatch(setLoggingInStatus({ isLoggingIn: false }));
    }
  };
  const resetPassword = async (email: string) => { };

  const updateProfile = () => { };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Auth0Context.Provider
      value={{
        ...state,
        isLoading,
        setRole,
        error,
        login,
        logout,
        resetPassword,
        updateProfile,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

export default Auth0Context;
