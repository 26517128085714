import { EMPLOYEE_APP_DEFAULT_PATH } from "config"
import { isEmpty } from "lodash"
export const getPathFromInternalLink = (redirectedURl: string) => {
    let path = ''
    if (redirectedURl.includes("/employee/homeavc")) {
        path = 'home'
    } else if (redirectedURl.includes("/employee/manageavc")) {
        path = 'manage_my_shared_cost_avc'
    } else if (redirectedURl.includes("/employee/my-investment-advice")) {
        path = 'my_investment_advice'
    } else if (redirectedURl.includes("/employee/new-app-form")) {
        path = 'new_application'
    }
    return path
}

function dynamicallyCreateAnchorAndNavigate(url: string) {
    let anchorElement = document.createElement('a');
    anchorElement.href = url;
    document.body.appendChild(anchorElement);
    anchorElement.click();
    document.body.removeChild(anchorElement);
  }
  
  function openInNewTab(url: string){
    dynamicallyCreateAnchorAndNavigate(url);
  }

export const navigateToInternalLink = (path: string) => {
    const client_id = process.env.REACT_APP_SILENT_LOGIN_CLIENT_ID;
    const callback = process.env.REACT_APP_AUTH_CALLBACK;
    const redirect_uri = `${callback}?path=${path}`;
    const domain = process.env.REACT_APP_AUTH_DOMAIN;
    const authUrl = `https://${domain}/authorize?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=openid profile email&prompt=none`;
    openInNewTab(authUrl)
    localStorage.removeItem('baseURl')
}

export const getBaseURL = () => {
    const baseURl = localStorage.getItem("baseURl") ?? EMPLOYEE_APP_DEFAULT_PATH
    const deepURL = getPathFromInternalLink(baseURl)
    if (!isEmpty(deepURL)) {
        navigateToInternalLink(deepURL)
    }
    return baseURl
}
