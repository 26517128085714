import { createApi } from "@reduxjs/toolkit/query/react";
import { ApiConstant } from "../../constants/ApiConstants";
import baseQuery from "./baseQuery";
import moment from "moment";
import {
  DownloadDashboardEmployeeResponse,
  DownloadDashboardSampleFileParam,
  FilterParam,
  ProductTracking,
  ProductTrackingResponse,
  ProductWiseEmployeeList,
} from "types/dashboard";
import { productApi } from "./product";
import captureSentryError from "helper/captureSentryError";

export const DASHBOARD_API_REDUCER_KEY = "dashboardApi";

export const dashboardApi = createApi({
  reducerPath: DASHBOARD_API_REDUCER_KEY,
  baseQuery,
  tagTypes: ["Dashboard"],
  endpoints: (builder) => ({
    getProductWiseEmployeeList: builder.query<
      ProductWiseEmployeeList,
      Partial<FilterParam>
    >({
      query: (args: FilterParam) => {
        const {
          StartDate = null,
          EndDate = null,
          EmployerID = "",
          RoleID = "",
          ProductID = [],
        } = args;
        const body = {
          StartDate: StartDate === null ? new Date() : StartDate,
          EndDate: EndDate === null ? new Date() : EndDate,
          EmployerID,
          RoleID,
          ProductID,
        };
        return {
          url: ApiConstant.DASHBOARD_EMPLOYEE_LISTING,
          method: "POST",
          body: { ...body },
        };
      },
      providesTags: ["Dashboard"],
    }),
    getAllProductWiseEmployeeList: builder.query<
      ProductWiseEmployeeList,
      Partial<FilterParam>
    >({
      query: (args: FilterParam) => {
        const {
          pageNo = 1,
          size = 30,
          EmployerID = "",
          RoleID = "",
          StartDate = null,
          EndDate = null,
          searchText = "",
          ProductID = [],
          sortColumn = "",
          sortOrder = "",
        } = args;
        const body = {
          pageNo,
          size,
          EmployerID,
          RoleID,
          StartDate,
          EndDate,
          searchText,
          ProductID,
          sortColumn,
          sortOrder,
        };
        return {
          url: ApiConstant.DASHBOARD_PRODUCTWISE_EMPLOYEE_LISTING,
          method: "POST",
          body: { ...body },
        };
      },
      providesTags: ["Dashboard"],
    }),
    showProductInterest: builder.mutation<
      ProductTrackingResponse,
      Partial<ProductTracking>
    >({
      query(body) {
        return {
          url: `${ApiConstant.SHOW_PRODUCT_INTEREST}`,
          method: "POST",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }) => response.data,
      invalidatesTags: ["Dashboard"],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(productApi.util.invalidateTags(["Product"]));
        } catch (error) {
          captureSentryError(error)
          console.log("Error", error);
        }
      },
    }),

    downloadSampleDashboardEmployeeFile: builder.query<
      DownloadDashboardEmployeeResponse,
      Partial<DownloadDashboardSampleFileParam>
    >({
      query: (body: DownloadDashboardSampleFileParam) => {
        return {
          url: ApiConstant.DOWNLOAD_DASHBOARD_SAMPLE_FILE,
          method: "POST",
          body,
        };
      },
      providesTags: ["Dashboard"],
    }),
  }),
});

export const {
  useGetProductWiseEmployeeListQuery,
  useGetAllProductWiseEmployeeListQuery,
  useShowProductInterestMutation,
  useLazyDownloadSampleDashboardEmployeeFileQuery,
} = dashboardApi;
