import { useMemo, useState, useCallback } from "react";
import { useFilter } from "../../hooks/useFilter";
import ReactTable from "../../components/ReactTable";
import { useGetEmployeeListQuery } from "store/reducers/employee";
import { useSelector } from "store";
import { EmployeeRowInfo } from "types/employee";
import moment from "moment";
import { HeaderGroup } from "react-table";
import { UserSwitchOutlined } from "@ant-design/icons";
import { Checkbox, FormControlLabel } from "@mui/material";

const EmployeeList = () => {
  const auth = useSelector((state) => state.auth);
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(false);
  const [sortColumn, setSortColumn] = useState("EmployeeNumber");
  const [sortOrder, setSortOrder] = useState("ASC");
  const [showPastEmployees, setShowPastEmployees] = useState(false);

  const { filter, setsearchParams } = useFilter();
  const pageIndex = filter("page") ? parseInt(filter("page")) : 1;
  const pageSize = filter("pageSize") ? parseInt(filter("pageSize")) : 30;

  const { data, isFetching } = useGetEmployeeListQuery(
    {
      pageNo: pageIndex,
      size: pageSize,
      searchText: searchText,
      EmployerID: auth.user?.EmployerID,
      RoleID: auth.user?.RoleID,
      sortColumn,
      sortOrder,
      showPastEmployees,
    },
    { refetchOnMountOrArgChange: true }
  );

  const FirstNameTypeComponent = useCallback((props: EmployeeRowInfo) => {
    const { row } = props;

    return (
      <>
        {row?.values?.FirstName ? row?.values?.FirstName : "-"}
        {row?.original?.IsAdminForAnyOrg === true && (
          <UserSwitchOutlined className="admin-icon" />
        )}
      </>
    );
  }, []);

  const EmailTypeComponent = useCallback((props: EmployeeRowInfo) => {
    const { row } = props;
    return <>{row?.values?.Email ? row?.values?.Email : "-"}</>;
  }, []);

  const EmployeeNoTypeComponent = useCallback((props: EmployeeRowInfo) => {
    const { row } = props;
    return (
      <>{row?.values?.EmployeeNumber ? row?.values?.EmployeeNumber : "-"}</>
    );
  }, []);

  const NiNoTypeComponent = useCallback((props: EmployeeRowInfo) => {
    const { row } = props;
    return <>{row?.values?.NiNumber ? row?.values?.NiNumber : "-"}</>;
  }, []);

  const LeaveDateTypeComponent = useCallback((props: EmployeeRowInfo) => {
    const { row } = props;
    return (
      <>
        {row?.values?.LeaveDate
          ? moment(row?.values?.LeaveDate).format("DD-MMM-YYYY")
          : "-"}
      </>
    );
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "FirstName",
        Cell: FirstNameTypeComponent,
      },
      {
        Header: "Last Name",
        accessor: "LastName",
      },
      {
        Header: "Email Address",
        accessor: "Email",
        Cell: EmailTypeComponent,
      },
      {
        Header: "Employee Number",
        accessor: "EmployeeNumber",
        Cell: EmployeeNoTypeComponent,
      },
      {
        Header: "NI Number",
        accessor: "NiNumber",
        Cell: NiNoTypeComponent,
      },
      {
        Header: "Leave Date",
        accessor: "LeaveDate",
        Cell: LeaveDateTypeComponent,
      },
    ],
    [
      EmailTypeComponent,
      EmployeeNoTypeComponent,
      FirstNameTypeComponent,
      LeaveDateTypeComponent,
      NiNoTypeComponent,
    ]
  );

  const onSearchData = (val: string) => {
    if (val) {
      setSearchText(val);
    } else {
      setSearchText("");
    }
    setsearchParams("page", "1");
  };

  const onChangePageIndex = (index: number) => {
    setsearchParams("page", index.toString());
  };

  const setPageSize = (index: number) => {
    setsearchParams("pageSize", index.toString());
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleSort = (column: HeaderGroup) => {
    setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC");
    setSortColumn(column.id);
  };

  const handleCheckboxClick = () => {
    setShowPastEmployees(!showPastEmployees);
  };

  const showCheckbox = () => {
    return (
      <FormControlLabel
        value="Show Past Employees"
        control={<Checkbox onChange={handleCheckboxClick} />}
        label="Show Past Employees"
        labelPlacement="end"
        sx={{ mr: 1 }}
      />
    );
  };

  return (
    <ReactTable
      columns={columns}
      data={data?.data}
      globalFilter={searchText}
      setGlobalFilter={onSearchData}
      pageNumber={pageIndex}
      isFetching={isFetching}
      totalRecords={data?.totalRecords || 0}
      onChangePageIndex={onChangePageIndex}
      pageSize={pageSize}
      setPageSize={setPageSize}
      open={open}
      handleToggle={handleToggle}
      handleSort={handleSort}
      showCheckbox={showCheckbox()}
    ></ReactTable>
  );
};

export default EmployeeList;
