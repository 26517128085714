import { createApi } from "@reduxjs/toolkit/query/react";
import { ApiConstant } from "../../constants/ApiConstants";
import baseQuery from "./baseQuery";
import {
  AddEmployee,
  DownloadEmployeeResponse,
  DownloadSampleFileParam,
  EmployeeList,
  SearchParam,
} from "types/employee";

export const EMPLOYEE_API_REDUCER_KEY = "employeeApi";

export const employeeApi = createApi({
  reducerPath: EMPLOYEE_API_REDUCER_KEY,
  baseQuery,
  tagTypes: ["Employee"],
  endpoints: (builder) => ({
    getEmployeeList: builder.query<EmployeeList, Partial<SearchParam>>({
      query: (args: SearchParam) => {
        const {
          pageNo = 1,
          size = 30,
          searchText = "",
          EmployerID = "",
          RoleID = "",
          sortColumn = "",
          sortOrder = "",
          showPastEmployees = false,
        } = args;
        const body = {
          pageNo,
          size,
          EmployerID,
          RoleID,
          sortColumn,
          sortOrder,
          showPastEmployees,
        };
        return {
          url: ApiConstant.EMPLOYEE_LISTING,
          method: "POST",
          body: { ...body, searchText },
        };
      },
      providesTags: ["Employee"],
    }),
    bulkImportEmployee: builder.mutation<{}, AddEmployee>({
      query(body) {
        return {
          url: `${ApiConstant.BULK_IMPORT_EMPLOYEE}`,
          method: "POST",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }) => response.data.error,
      invalidatesTags: ["Employee"],
    }),
    downloadSampleEmployeeFile: builder.query<
      DownloadEmployeeResponse,
      Partial<DownloadSampleFileParam>
    >({
      query: (body: DownloadSampleFileParam) => {
        return {
          url: ApiConstant.DOWNLOAD_SAMPLE_FILE,
          method: "POST",
          body,
        };
      },
      providesTags: ["Employee"],
    }),
  }),
});

export const {
  useGetEmployeeListQuery,
  useBulkImportEmployeeMutation,
  useLazyDownloadSampleEmployeeFileQuery,
} = employeeApi;
