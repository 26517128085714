import { createApi } from "@reduxjs/toolkit/query/react";
import { ApiConstant } from "../../constants/ApiConstants";
import baseQuery from "./baseQuery";
import {
  ByEmployer,
  Questionary,
  ThemeList,
  ThemeListInfo,
  ThemeScore,
} from "types/financialhealthcheck";

export const FINANCIAL_HEALTH_CHECK = "financialHealthCheck";

export const financialHealthCheckApi = createApi({
  reducerPath: FINANCIAL_HEALTH_CHECK,
  baseQuery,
  tagTypes: ["financialHealthCheckApi", "getThemeResponse"],
  endpoints: (builder) => ({
    getAllActiveThemes: builder.query<ThemeList, {}>({
      query: () => {
        return {
          url: ApiConstant.GET_FINANCIAL_HEALTH_CHECKUP,
          method: "GET",
        };
      },
      providesTags: ["financialHealthCheckApi"],
    }),
    submitQuestionary: builder.mutation<ThemeScore, Partial<Questionary>>({
      query: (body: Questionary) => {
        return {
          url: ApiConstant.SUBMIT_QUESTIONARY,
          method: "POST",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }): string => response.data.error,
      invalidatesTags: ["getThemeResponse"],
    }),
    getThemeResponse: builder.query<ThemeScore, ByEmployer>({
      query: (body) => {
        return {
          url: ApiConstant.GET_THEME_RESPONSE,
          method: "POST",
          body,
        };
      },
      providesTags: ["getThemeResponse"],
    }),
    getThemeOnDashboardResponse: builder.query<ThemeListInfo, ByEmployer>({
      query: (body) => {
        return {
          url: ApiConstant.GET_THEME_EMP_DASHBOARD,
          method: "POST",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }): any => response.data,
      providesTags: ["getThemeResponse"],
    }),
  }),
});

export const {
  useGetAllActiveThemesQuery,
  useSubmitQuestionaryMutation,
  useGetThemeResponseQuery,
  useGetThemeOnDashboardResponseQuery,
} = financialHealthCheckApi;
