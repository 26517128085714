import { useLocation, Navigate } from "react-router-dom";
import { getBaseURL } from "utils/utils";
import useAuth from "../../hooks/useAuth";

import { GuardProps } from "../../types/auth";
import { ADMIN_APP_DEFAULT_PATH, EMPLOYEE_APP_DEFAULT_PATH } from "config";
import Loader from "components/Loader";

const AuthGuard = ({ children }: GuardProps) => {
  const { isLoggedIn, user, isLoggingIn, error, isLoggingOut } = useAuth();
  const location = useLocation();
  console.log("AuthGuard", location?.state?.from)
  if (isLoggingIn || isLoggingOut) {
    return <Loader />;
  }
  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (error) {
    return (
      <Navigate to="/unauthorized-access" state={{ from: location }} replace />
    );
  } else if (user?.RoleID && user?.RoleName) {
    const baseURl = getBaseURL()
    console.log("AuthGuard baseUrl" ,baseURl)
    const default_path =
      user?.RoleName === "Employee"
        ? baseURl
        : ADMIN_APP_DEFAULT_PATH;
    return <Navigate to={default_path} state={{ from: location }} replace />;
  }

  return children;
};

export default AuthGuard;
