import { createApi } from "@reduxjs/toolkit/query/react";
import { ApiConstant } from "../../constants/ApiConstants";
import baseQuery from "./baseQuery";
import {
  WebinarList,
  SearchParam,
  BookWebinarData,
  BookWebinar,
  GetWebinarInfo,
  UpdateWebinarInfo,
  CheckAvaliblityParam,
  ThemeWebinarList,
  ThemeWebinarInfo,
} from "types/webinar";

export const WEBINAR_API_REDUCER_KEY = "webinar";

export const webinarApi = createApi({
  reducerPath: WEBINAR_API_REDUCER_KEY,
  baseQuery,
  tagTypes: ["Webinar"],
  endpoints: (builder) => ({
    getAllWebinar: builder.query<WebinarList, Partial<SearchParam>>({
      query: (args: SearchParam) => {
        const { pageNo = 1, size = 30, EmployerID, filter } = args;
        const body = { pageNo, size, employerID: EmployerID};
        return {
          url: ApiConstant.GET_ALL_WEBINAR,
          method: "POST",
          body: filter?.length ? { ...body, filter } : { ...body },
        };
      },
      serializeQueryArgs: ({ queryArgs }) => {
        const newQueryArgs = { ...queryArgs };
        if (newQueryArgs.pageNo) {
          delete newQueryArgs.pageNo;
        }
        if (!newQueryArgs.hasOwnProperty("filter")) {
          newQueryArgs["filter"] = [];
        }
        return newQueryArgs;
      },
      merge: (currentCache, newItems) => {
        if (currentCache.data) {
          const filterNewData = newItems.data.filter(
            (res) => !currentCache.data.find((d) => d.ID === res.ID)
          );
          return {
            ...currentCache,
            ...newItems,
            data: [...currentCache.data, ...filterNewData],
          };
        }
        return newItems;
      },
      providesTags: ["Webinar"],
    }),
    getCheckAvliablity: builder.query<
      WebinarList,
      Partial<CheckAvaliblityParam>
    >({
      query: (body: CheckAvaliblityParam) => {
        return {
          url: ApiConstant.GET_WEBINAR_CHECKAVLIABLITY,
          method: "POST",
          body,
        };
      },
      providesTags: ["Webinar"],
    }),
    bookWebinar: builder.mutation<BookWebinarData, Partial<BookWebinar>>({
      query(body) {
        return {
          url: ApiConstant.BOOK_WEBINAR,
          method: "POST",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }): string => response.data.error,
      invalidatesTags: ["Webinar"],
    }),
    cancelBookingWebinar: builder.mutation<
      UpdateWebinarInfo,
      Partial<GetWebinarInfo>
    >({
      query({ ID, ...body }) {
        return {
          url: `${ApiConstant.CANCEL_WEBINAR}/${ID}`,
          method: "PATCH",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }) => response.data.error,
      invalidatesTags: ["Webinar"],
    }),
    viewMyBookings: builder.query<BookWebinarData, Partial<SearchParam>>({
      query: (args) => {
        const { pageNo = 1, size = 30, EmployerID } = args;
        const body = { pageNo, size, employerID: EmployerID};
        return {
          url: ApiConstant.VIEW_MY_BOOKINGS,
          method: "POST",
          body,
        };
      },
      providesTags: ["Webinar"],
    }),
    getWebinarByTheme: builder.query<ThemeWebinarList, Partial<SearchParam>>({
      query: (args: SearchParam) => {
        const { pageNo = 1, size = 30, EmployerID, ThemeID } = args;
        const body = { pageNo, size, EmployerID, ThemeID };
        return {
          url: ApiConstant.GET_WEBINAR_BY_THEME,
          method: "POST",
          body: body,
        };
      },
      serializeQueryArgs: ({ queryArgs }) => {
        const newQueryArgs = { ...queryArgs };
        if (newQueryArgs.pageNo) {
          delete newQueryArgs.pageNo;
        }
        if (!newQueryArgs.hasOwnProperty("filter")) {
          newQueryArgs["filter"] = [];
        }
        return newQueryArgs;
      },
      merge: (currentCache, newItems) => {
        if (currentCache.data) {
          const filterNewData = newItems.data.webinars.filter(
            (res) => !currentCache.data.webinars.find((d) => d.ID === res.ID)
          );
          const mergedData: ThemeWebinarInfo = {
            ...currentCache.data,
            webinars: [...currentCache.data.webinars, ...filterNewData],
          };
          return {
            ...currentCache,
            ...newItems,
            data: mergedData,
          };
        }
        return newItems;
      },
      providesTags: ["Webinar"],
    }),
  }),
});

export const {
  useGetAllWebinarQuery,
  useLazyGetAllWebinarQuery,
  useBookWebinarMutation,
  useCancelBookingWebinarMutation,
  useViewMyBookingsQuery,
  useGetCheckAvliablityQuery,
  useGetWebinarByThemeQuery,
} = webinarApi;
