import { Navigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import Loader from "components/Loader";

import { GuardProps } from "../../types/auth";
import RoleGuard from "./RoleGuard";
import { EMPLOYEE_APP_DEFAULT_PATH } from "config";

const AdminRoleGuard = ({ children }: GuardProps) => {
  const { user, isLoggingOut, isLoggingIn, isLoggedIn } = useAuth();

  if (isLoggingOut || isLoggingIn) {
    return <Loader />;
  }
  if (user?.RoleName !== "Admin" && isLoggedIn) {
    return <Navigate to={EMPLOYEE_APP_DEFAULT_PATH} replace />;
  } else {
    return <RoleGuard>{children}</RoleGuard>;
  }
};

export default AdminRoleGuard;
