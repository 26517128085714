import { lazy } from "react";

import GuestGuard from "../utils/route-guard/GuestGuard";
import Loadable from "../components/Loadable";
import { Outlet } from "react-router";

// render - login
const AuthLogin = Loadable(lazy(() => import("../pages/auth/login")));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: (
        <GuestGuard>
          <Outlet />
        </GuestGuard>
      ),
      children: [
        {
          path: "login",
          element: <AuthLogin />,
        },
      ],
    },
  ],
};

export default LoginRoutes;
