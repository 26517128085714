import { styled } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import { useSelector } from "../../store";

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";

const StyledSnackbarProvider = styled(SnackbarProvider)(({ theme }) => ({
  "&.notistack-MuiContent-default": {
    backgroundColor: theme.palette.primary.main,
  },
  "&.notistack-MuiContent-error": {
    backgroundColor: theme.palette.error.main,
  },
  "&.notistack-MuiContent-success": {
    backgroundColor: theme.palette.success.main,
  },
  "&.notistack-MuiContent-info": {
    backgroundColor: theme.palette.info.main,
  },
  "&.notistack-MuiContent-warning": {
    backgroundColor: theme.palette.warning.main,
  },
}));

const Notistack = ({ children }: any) => {
  const snackbar = useSelector((state: any) => state.snackbar);
  const iconSX = { marginRight: 8, fontSize: "1.15rem" };

  return (
    <StyledSnackbarProvider
      maxSnack={snackbar.maxStack}
      dense={snackbar.dense}
      iconVariant={
        snackbar.iconVariant === "useemojis"
          ? {
              success: <CheckCircleOutlined style={iconSX} />,
              error: <CloseCircleOutlined style={iconSX} />,
              warning: <WarningOutlined style={iconSX} />,
              info: <InfoCircleOutlined style={iconSX} />,
            }
          : undefined
      }
      hideIconVariant={snackbar.iconVariant === "hide" ? true : false}
    >
      {children}
    </StyledSnackbarProvider>
  );
};

export default Notistack;
