import { configureStore } from "@reduxjs/toolkit";
import {
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
  TypedUseSelectorHook,
} from "react-redux";
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import logger from "redux-logger";
import reducers from "./reducers";
import { profileApi } from "./reducers/profile";
import { productApi } from "./reducers/product";
import { orgRolesApi } from "./reducers/roles";
import { employerAdminProfile } from "./reducers/employerAdminProfile";
import { employeeApi } from "./reducers/employee";
import { dashboardApi } from "./reducers/dashboard";
import { adminMyprofileApi } from "./reducers/adminMyProfile";
import { webinarApi } from './reducers/webinar'
import { financialHealthCheckApi } from "./reducers/financialHealthCheck";

const middleware = [
  employeeApi.middleware,
  dashboardApi.middleware,
  orgRolesApi.middleware,
  profileApi.middleware,
  productApi.middleware,
  employerAdminProfile.middleware,
  adminMyprofileApi.middleware,
  webinarApi.middleware,
  financialHealthCheckApi.middleware
]

if (process.env.REACT_APP_ENV === "DEVELOPMENT") {
  middleware.push(logger)
}

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(middleware),
});

export type RootState = ReturnType<typeof reducers>;

export type AppDispatch = typeof store.dispatch;

const persister = persistStore(store);

const { dispatch } = store;

const useDispatch = () => useAppDispatch<AppDispatch>();
const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;

export { store, dispatch, persister, useSelector, useDispatch };
