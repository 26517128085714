import { createApi } from "@reduxjs/toolkit/query/react";
import { ApiConstant } from "../../constants/ApiConstants";
import baseQuery from "./baseQuery";
import {
  ActivePensionFundist,
  EmployerProfileInfo,
  FileUpload,
  GetAllEmployerProfileInfo,
  ManageEmployerProfileData,
  PensionFundist,
  RemoveFile,
  RemoveFileData,
  UpdateEmployerProfileData,
  UploadFile,
} from "types/employerAdminProfile";

export const EMP_ADMIN_PROFILE_API_REDUCER_KEY = "employerAdminProfile";

export const employerAdminProfile = createApi({
  reducerPath: EMP_ADMIN_PROFILE_API_REDUCER_KEY,
  baseQuery,
  tagTypes: ["EmployerAdminProfile"],
  endpoints: (builder) => ({
    getEmployerAdminProfileById: builder.query<
      ManageEmployerProfileData,
      Partial<EmployerProfileInfo>
    >({
      query: (body: EmployerProfileInfo) => {
        const { EmployerID, RoleID } = body;
        return {
          url: ApiConstant.GET_EMP_BY_ID_PROFILE,
          method: "POST",
          body: { EmployerID, RoleID },
        };
      },
      providesTags: ["EmployerAdminProfile"],
    }),
    fileUploader: builder.mutation<FileUpload, UploadFile>({
      query(body) {
        return {
          url: `employer/fileUpload`,
          method: "POST",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }) => response.data.error,
    }),
    removeFile: builder.mutation<RemoveFileData, RemoveFile>({
      query(body) {
        return {
          url: `employer/deleteFile`,
          method: "POST",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }) => response.data.error,
    }),
    editEmployerAdminProfile: builder.mutation<
      UpdateEmployerProfileData,
      Partial<GetAllEmployerProfileInfo>
    >({
      query(body) {
        return {
          url: `${ApiConstant.UPDATE_EMP_PROFILE}`,
          method: "PATCH",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }) => response.data.error,
      invalidatesTags: ["EmployerAdminProfile"],
    }),
    getPensionFundList: builder.query<PensionFundist, string>({
      query: (id: string) => {
        return {
          url: `${ApiConstant.GET_PENSION_FUNDS_LIST}/${id}`,
          method: "GET",
        };
      },
      providesTags: ["EmployerAdminProfile"],
    }),
    getActivePensionFundList: builder.query<ActivePensionFundist, {}>({
      query: () => {
        return {
          url: ApiConstant.GET_ALL_ACTIVE_PENSION_FUNDS,
          method: "GET",
        };
      },
      providesTags: ["EmployerAdminProfile"],
    }),
  }),
});

export const {
  useGetEmployerAdminProfileByIdQuery,
  useRemoveFileMutation,
  useFileUploaderMutation,
  useEditEmployerAdminProfileMutation,
  useGetPensionFundListQuery,
  useGetActivePensionFundListQuery,
} = employerAdminProfile;
