import React, { useEffect } from "react";

import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  useTable,
  useFilters,
  usePagination,
  Column,
  HeaderGroup,
  Row,
  Cell,
  useSortBy,
} from "react-table";
import { SearchFilter } from "../utils/react-table";
import { EcoTablePagination, HeaderSort } from "./third-party/ReactTable";

import NoDataFound from "./NoDataFound";
import LottieLoader from "./LottieLoader";

const emptyArray: any[] = [];

export interface LabelKeyObject {
  label: string;
  key: string;
}

const ReactTable = ({
  columns,
  data,
  globalFilter,
  setGlobalFilter,
  isFetching,
  pageNumber,
  pageSize,
  totalRecords = 0,
  onChangePageIndex,
  setPageSize,
  children,
  open,
  handleToggle,
  VisibleColumn,
  getHeaderProps,
  setVisibleColumns,
  handleSort,
  showCheckbox,
}: {
  columns: Column[];
  data?: any[];
  globalFilter?: string;
  setGlobalFilter?: (value: string) => void;
  onChangePageIndex: (value: number) => void;
  setPageSize: (value: number) => void;
  isFetching: any;
  totalRecords: number;
  pageNumber: number;
  pageSize: number;
  children?: React.ReactNode;
  open?: boolean;
  handleToggle?: () => void;
  VisibleColumn?: any[];
  getHeaderProps?: any;
  setVisibleColumns?: (arr: string[]) => void;
  handleSort: (column: HeaderGroup) => void;
  showCheckbox?: React.ReactNode;
}) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
  } = useTable(
    {
      columns,
      data: data || emptyArray,
      manualPagination: true,
      initialState: {
        pageIndex: 0,
        pageSize: pageSize,
        hiddenColumns: columns
          .filter((col: Column<{}>) =>
            VisibleColumn?.includes(col.accessor as string)
          )
          .map((col) => col.accessor) as string[],
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const onPageChange = (pageNo: number) => {
    onChangePageIndex(pageNo);
  };

  return (
    <Stack className="custom-table-container">
      {(globalFilter !== undefined || children) && (
        <Stack
          className="custom-table-filter"
          direction={matchDownSM ? "column" : "row"}
          spacing={3}
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 3 }}
        >
          {globalFilter !== undefined && setGlobalFilter !== undefined && (
            <>
              {showCheckbox}
              <SearchFilter
                className="custom-table-search"
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                size="small"
              />
            </>
          )}
          <div className="role-header">
            <Stack
              direction={matchDownSM ? "column" : "row"}
              spacing={3}
              justifyContent="flex-end"
              alignItems="center"
              sx={{ mr: 2 }}
            >
              {children}
            </Stack>
          </div>
        </Stack>
      )}
      <div className="custom-table-responsive">
        <Table className="custom-table" {...getTableProps()} size="small">
          <TableHead sx={{ borderTopWidth: 1 }}>
            {headerGroups.map((headerGroup: any) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: HeaderGroup) => (
                  <TableCell
                    {...column.getHeaderProps([
                      getHeaderProps ? getHeaderProps(column) : {},
                    ])}
                  >
                    <HeaderSort
                      column={column}
                      handleSort={() => handleSort(column)}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {isFetching ? (
              <TableRow>
                <TableCell colSpan={11}>
                  <Typography
                    component="div"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height="400px"
                  >
                    {/* <CircularProgress sx={{ color: "#391474" }} size={80} /> */}
                    <LottieLoader />
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {page?.length > 0 ? (
                  <>
                    {page.map((row: Row, index: any) => {
                      prepareRow(row);
                      return (
                        <TableRow {...row.getRowProps()}>
                          {getHeaderProps && (
                            <TableCell>
                              {(pageNumber - 1) * pageSize + (index + 1)}
                            </TableCell>
                          )}
                          {row.cells.map((cell: Cell) => (
                            <TableCell {...cell.getCellProps([])}>
                              {cell.render("Cell")}
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    })}
                  </>
                ) : (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <NoDataFound />
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </div>

      <div className="custom-table-footer">
        <div>
          <EcoTablePagination
            gotoPage={onPageChange}
            rows={rows}
            setPageSize={setPageSize}
            pageIndex={pageNumber}
            pageSize={pageSize}
            totalRecords={totalRecords}
          />
        </div>
      </div>
      {children}
    </Stack>
  );
};

export default ReactTable;
