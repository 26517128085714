import { Suspense, lazy } from "react";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Notistack from "../../components/third-party/Notistack";
import useAuth from "hooks/useAuth";

const Header = lazy(() => import("./Header"));
const Footer = lazy(() => import("./Footer"));

const LoaderWrapper = styled("div")(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 2001,
  width: "100%",
  "& > * + *": {
    marginTop: theme.spacing(2),
  },
}));

export interface LoaderProps extends LinearProgressProps {}

const Loader = () => (
  <LoaderWrapper>
    <LinearProgress color="primary" />
  </LoaderWrapper>
);

const MainLayout = () => {
  const {isLoading} = useAuth()
  if (isLoading) {
    return <Loader />
  } else {
    return (
      <Suspense fallback={<Loader />}>
        {/* <Notistack> */}
          <Header />
          <Outlet />
          <Footer />
        {/* </Notistack> */}
      </Suspense>
    );
  }
};

export default MainLayout;
