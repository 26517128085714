import { GuardProps } from 'types/auth';
import { Navigate } from 'react-router';
import { useSelector } from 'store';
import { getAuthInfo } from 'selectors/auth';
const PermissionGuard = (props: GuardProps) => {
    const { children } = props
    const auth = useSelector((state) => getAuthInfo(state));

    const isAccess = Boolean(auth.user?.HealthCheckToolDisable)

    console.log("isAccess", auth)

    if (isAccess) {
        return (
            <Navigate to="/unauthorized-access" replace />
        );
    }

    return children;
};

export default PermissionGuard;
