import { createApi } from "@reduxjs/toolkit/query/react";
import { ApiConstant } from "../../constants/ApiConstants";
import baseQuery from "./baseQuery";
import { OrgRoleList, SearchParam} from "types/roles";

export const EMPLOYER_API_REDUCER_KEY = "orgRolesApi";

export const orgRolesApi = createApi({
  reducerPath: EMPLOYER_API_REDUCER_KEY,
  baseQuery,
  tagTypes: ["OrgRoles"],
  endpoints: (builder) => ({
    getOrgRolesList: builder.query<OrgRoleList, Partial<SearchParam>>({
      query: () => {
        return {
          url: ApiConstant.ORG_ROLES,
          method: "GET",
        };
      },
      providesTags: ["OrgRoles"],
    }),
  }),
});

export const { useLazyGetOrgRolesListQuery , useGetOrgRolesListQuery} = orgRolesApi;
