import useAuth from "../../hooks/useAuth";
import Loader from "components/Loader";

import { GuardProps } from "../../types/auth";
import RoleGuard from "./RoleGuard";
import { Navigate } from "react-router";
import { ADMIN_APP_DEFAULT_PATH } from "config";

const EmployeeRoleGuard = ({ children }: GuardProps) => {
  const { user, isLoggingOut, isLoggingIn, isLoggedIn } = useAuth();
  if (isLoggingOut || isLoggingIn) {
    return <Loader />;
  }
  if (user?.RoleName !== "Employee" && isLoggedIn) {
    return <Navigate to={ADMIN_APP_DEFAULT_PATH} replace />;
  } else {
    return <RoleGuard>{children}</RoleGuard>;
  }
};

export default EmployeeRoleGuard;
