import { lazy } from "react";
import Loadable from "../components/Loadable";
import { Outlet } from "react-router";
import AuthGuard from "utils/route-guard/AuthGuard";
const SelectRole = Loadable(lazy(() => import("../pages/auth/SelectRole")));

// ==============================|| AUTH ROUTING ||============================== //

const AuthRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: (
        <AuthGuard>
          <Outlet />
        </AuthGuard>
      ),
      children: [
        {
          path: "select-role",
          element: <SelectRole />,
        },
      ],
    },
  ],
};

export default AuthRoutes;
