import { createApi } from "@reduxjs/toolkit/query/react";
import { ApiConstant } from "../../constants/ApiConstants";
import baseQuery from "./baseQuery";
import {
  GetAllMyProfileInfo,
  ManageMyProfileData,
  MyProfileInfo,
  UpdateMyProfileData,
} from "types/adminMyProfile";

export const ADMIN_MY_PROFILE_API_REDUCER_KEY = "adminMyprofileApi";

export const adminMyprofileApi = createApi({
  reducerPath: ADMIN_MY_PROFILE_API_REDUCER_KEY,
  baseQuery,
  tagTypes: ["adminMyprofile"],
  endpoints: (builder) => ({
    getMyProfileById: builder.query<
      ManageMyProfileData,
      Partial<MyProfileInfo>
    >({
      query: (body: MyProfileInfo) => {
        const { EmployerID, RoleID } = body;
        return {
          url: ApiConstant.GET_BY_ID_MY_PROFILE,
          method: "POST",
          body: { EmployerID, RoleID },
        };
      },
      providesTags: ["adminMyprofile"],
    }),
    editMyProfile: builder.mutation<
      UpdateMyProfileData,
      Partial<GetAllMyProfileInfo>
    >({
      query(body) {
        return {
          url: `${ApiConstant.UPDATE_MY_PROFILE}`,
          method: "POST",
          body,
        };
      },
      transformErrorResponse: (response: {
        data: { error: string; message: string };
        status: number;
      }) => response.data.error,
      invalidatesTags: ["adminMyprofile"],
    }),
  }),
});

export const { useGetMyProfileByIdQuery, useEditMyProfileMutation } =
  adminMyprofileApi;
